import React, { useState, useEffect } from 'react';
import img from './IMG_2826.png';  // Ensure path to image is correct
import './App.css'; // Ensure CSS file is correctly linked

function App() {
  // State to manage theme
  const [isDarkMode, setIsDarkMode] = useState(true);

  // Effect to apply class to body for theming
  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
  }, [isDarkMode]);

  // Function to toggle theme
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  
  return (
    <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
      <div className="header">
        <img className='img' src={img} alt="My Logo" />
        <div className="links">
          <a href="https://twitter.com/colton_pinner" target="_blank" rel="noopener noreferrer" className="social-link">
            <img width="30" height="30" src="https://img.icons8.com/ios-filled/50/82d7f7/twitterx--v1.png" alt="Twitter Icon" />
          </a>
          <a href="https://github.com/ColtonPinner" target="_blank" rel="noopener noreferrer" className="social-link">
            <img width="30" height="30" src="https://img.icons8.com/ios-filled/50/82d7f7/github.png" alt="GitHub Icon" />
          </a>
          <a href="https://medium.com/@coltonpinner24" target="_blank" rel="noopener noreferrer" className="social-link">
            <img width="40" height="40" src="https://img.icons8.com/sf-black-filled/50/82d7f7/medium-logo.png" alt="medium-logo" />
          </a>
        </div>
        <button className="button" onClick={toggleTheme}>
          {isDarkMode ? '☀️' : '🌙'}
        </button>
      </div>
      <div className='intro'>
        <h1>👋🏼 Hey I'm Colton</h1>
        <p>
          As a passionate Full Stack Developer, I thrive on turning innovative ideas into robust, user-friendly applications. With a strong foundation in both front-end and back-end technologies, I bring a dynamic skill set to the table. From crafting seamless user experiences with responsive designs to optimizing server-side performance, I am dedicated to delivering top-notch solutions. Explore my portfolio to witness the intersection of creativity and functionality in every project.
          Let's build the future, one line of code at a time.
          Best regards,
          Colton Pinner
        </p>
      </div>
      <div className="skills">
        <h1>👨🏻‍💻 My Skills</h1>
        <p>
          <img width="96" height="96" src="https://img.icons8.com/fluency/96/node-js.png" alt="node-js" />
          <img width="96" height="96" src="https://img.icons8.com/color/96/javascript--v1.png" alt="javascript--v1" />
          <img width="96" height="96" src="https://img.icons8.com/color/96/vue-js.png" alt="vue-js" />
          <img width="96" height="96" src="https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/96/external-angular-a-typescript-based-open-source-web-application-framework-logo-shadow-tal-revivo.png" alt="external-angular-a-typescript-based-open-source-web-application-framework-logo-shadow-tal-revivo" />
          <img width="96" height="96" src="https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/96/61DAFB/external-react-social-media-tanah-basah-glyph-tanah-basah.png" alt="external-react-social-media-tanah-basah-glyph-tanah-basah" />
          <img width="96" height="96" src="https://img.icons8.com/color/96/swift.png" alt="swift" />
          <img width="96" height="96" src="https://img.icons8.com/color/96/html-5--v1.png" alt="html-5--v1" />
          <img width="96" height="96" src="https://img.icons8.com/color/96/css3.png" alt="css3" />
        </p>
      </div>
      <div className="tools">
        <h1>🛠️ Tools</h1>
        <img width="90" height="90" src="https://img.icons8.com/color/96/visual-studio-code-2019.png" alt="visual-studio-code-2019" />
        <img width="90" height="90" src="https://img.icons8.com/color/96/figma--v1.png" alt="figma--v1" />
        <img width="90" height="90" src="https://img.icons8.com/color/96/console.png" alt="console" />
        <img width="90" height="90" src="https://img.icons8.com/color/96/git.png" alt="git" />
        <img width="90" height="90" src="https://img.icons8.com/plasticine/100/sketch.png" alt="sketch" />
        <img width="90" height="90" src="https://img.icons8.com/color/96/notion--v1.png" alt="notion--v1" />
      </div>
      <div className="projects">
        <h1>🏗️ My Projects</h1>
        <div className="github-box">
          <h1>Mira | Smart Dashboard</h1>
          <p>Unlock the true potential of your data with the OpenSmart Dashboard, a cutting-edge open-source solution designed to revolutionize the way you interact with and interpret information. This smart dashboard is more than just a visual representation of data; it's a dynamic platform that empowers users to make informed decisions through intuitive insights.</p>
          <a className="github-link" href="https://github.com/ColtonPinner/Mira" target="_blank" rel="noopener noreferrer">View on GitHub</a>
        </div>
      </div>
      <div className="github-box">
        <h1>Portfolio</h1>
        <p>Welcome to Colton Pinner's Portfolio Showcase, a testament to my passion for Full Stack Development and my commitment to turning innovative ideas into robust, user-friendly applications. This platform serves as a curated collection of my most impactful projects, reflecting the intersection of creativity and functionality.</p>
        <a className="github-link" href="https://github.com/ColtonPinner/ColtonPinner-Portfolio" target="_blank" rel="noopener noreferrer">View on GitHub</a>
      </div>
      <div className="form">
        <h1>📬 Get in Touch</h1>
        <form action="https://formspree.io/f/mkndbdpg" method="POST">
          <label>
            <h2>Name</h2>
            <input type="text" name="name" required placeholder='Name' />
          </label>
          <label>
            <h2>Email</h2>
            <input type="email" name="_replyto" required placeholder='Email' />
          </label>
          <label>
            <h2>Message</h2>
            <textarea name="message" required placeholder='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'></textarea>
          </label>
          <button type="submit">Send it</button>
        </form>
      </div>
      <div className="footer">
        <p>© 2025 Colton Pinner. All rights reserved.</p>
      </div>
    </div>
  );
}

export default App;
